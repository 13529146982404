<template>
  <layout-vertical>

    <router-view />
    <!-- <ul slot="vertical-menu-header" class="nav navbar-nav flex-row">
      <li class="nav-item mr-auto">
        <a href="/demo/vuexy-vuejs-admin-dashboard-template/demo-1/" class="navbar-brand router-link-active" target="_self">
          <span class="brand-logo">
            <b-img :src="require('@/assets/images/CBL/logo.png')" />
          </span>
          <h2 class="brand-text padding-left-zero">Cipta Buana Lestari</h2>
        </a>
      </li>
    </ul> -->
    <div
      slot="vertical-menu-header"
      class="d-flex align-items-center h-100"
    >
      <h1 class="font-medium-5 mb-0 text-danger">
        Cipta Buana Lestari
      </h1>
    </div>
    <!-- Using navbar scoped slot -->
    <div
      slot="navbar"
      class="navbar-container d-flex content align-items-center"
    >
      <b-navbar-nav class="nav align-items-center ml-auto">
        <UserDropDown />
      </b-navbar-nav>
    </div>
    <div
      slot="breadcrumb"
    >
      <CustomBreadCrumb />
    </div>

    <div slot="footer">
      <p class="mb-0" style="font-weight:500;">
        Copyright &copy; Cipta Buana Lestari {{ new Date().getFullYear() }}
        v1.0.8
      </p>
    </div>
    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import UserDropDown from '@/views/components/user/UserDropDown.vue'
import CustomBreadCrumb from '@/views/components/custom-breadcrumb/breadcrumb.vue'
import {
  BNavbarNav, BImg
} from 'bootstrap-vue'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    UserDropDown,
    BNavbarNav,
    CustomBreadCrumb,
    BImg,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
